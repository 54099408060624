
:root {
  --bg: #494d46;
  --meta: #888;
  --accent: rgb(0, 92, 221);
  --text: #FAFAFA;
  --base: 1.5rem;
  --code: 1rem;
  --heading-font-family: "Poppins";
  --heading-font-weight: 800;
}

@media (max-width: 600px) {
  :root {
    --base: 1.2rem;
    --bg: #414141;
  }
  .header h4{
    font-size: 11px;
  }
}

* {
  box-sizing: border-box;
}


body,
html {
  font-family: Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: var(--base);
  -webkit-font-smoothing: antialiased;
  font-feature-settings: 'calt', 'liga', 'hist', 'onum', 'pnum';

  overflow: auto;

  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  color: var(--text);
  background-color: var(--bg);
}

#slide {
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  -webkit-overflow-scrolling: touch;
}