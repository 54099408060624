@media (max-width: 600px) {
    .mobile-info-container h6{
        font-size: 10px;
    }
  }
.mobile-container{
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 2px;
}
.mobile-image-container{
    height: 42%;
    width: 100%;
    overflow: hidden;
    margin-top: 0px;
    position: relative;
    
}
.vendor{
    position: absolute;
    top: 10px;
    left: 6px;
    text-transform: uppercase;
    font-size: 20px;
    text-shadow: 0 0 2px black;
    color: #FEFEFE;
    z-index: 2;
}
.mobile-image-container .large-image-radios-box{
    position: absolute;
    width: 100%;
    bottom: 20px;
    display: flex;
    justify-content: center;
    z-index: 2;
}
.mobile-info-container{
    height: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    text-align: center;
    text-transform: uppercase;
}
.mobile-info-container h5{
    text-transform: uppercase;
    font-size:14px;
}
.mobile-info-container hr{
    width: 80%;
    border: #414141 thin dashed;
    margin:2px;
}
.mobile-loader{
    z-index: 2;
}
.large-image-container{
    border-bottom: 3px solid black;
    border-bottom-left-radius: 10px;
    overflow: hidden;
    border-bottom-right-radius: 10px;
    height: 390px;
    background: repeating-linear-gradient(
    45deg,
    #494d4699,
    #494d4699 40px,
    #494d466c 40px,
    #494d466c 80px
  );
}
.scrollable-image-container img{
    height:390px;
    
}
.mobile-error{
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    padding: 0 20px;
}
.save-selection-button{
    width: 100%;
    position: absolute;
    bottom: 40px;
    height: 50px;
    border: none;
    background: #f4fb56;
    left:0;
    font-size: 14px;
}
.save-selection-button-stored{
    width: 100%;
    position: absolute;
    bottom: 40px;
    height: 18px;
    border: none;
    background: #f4fb56;
    color: black;
    padding: 1px;
}

.save-selection-button h5 {
    border: thin solid black;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    text-transform: uppercase;
}

.footer-items{
    display: flex;
    justify-content: center;
    border: thin solid;
    height: 28px;
    align-items: center;
    border-radius: 4px;
    padding: 0 10px;
    text-transform: uppercase;
    pointer-events: all;
    width:100%;
}
.mobile-pop{
    position: absolute;
    color: #FEFEFE;
    z-index: 11;
    top: 0px;
    left: 0;
    width: 100%;
    padding: 10px 1px;
    box-sizing: border-box;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}
.mobile-pop-scroll-box{
    height: 95%;
    overflow: scroll;
    width: 98%;
    scroll-behavior: smooth;
    margin: 10px 0;
    padding: 3px;
    background: #FFFFFF20;

}
.mobile-pop-close{
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 10;
}
.swatch-close{
    width: 27px;
    height: 27px;
    background: #FFFFFF50;
    border-radius: 50%;
    border: 2px solid white;
    filter: drop-shadow(2px 4px 6px black);
}

.mobile-overlay-items{
    height: 45px;
    border: thin solid black;
    border-radius: 3px;
    display: flex;
    padding: 5px;
    align-items: center;
    color: black;
    margin: 2px 0;
    text-transform: uppercase;
}
.mobile-overlay-items:active{
    border: thin solid #f4fb56;
    color: #f4fb56;
    background-color: #414141;
}
.mobile-swatch{
    height: 120px;
    width: 100%;
    background: repeating-linear-gradient(
        45deg,
        #494d4699,
        #494d4699 40px,
        #494d466c 40px,
        #494d466c 80px
      );
    border-radius: 13px;
    margin: 4px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    border: 2px #FEFEFE90 solid;
    overflow: hidden;
}
.mobile-swatch-image{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
}
.mobile-swatch-info{
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 30px;
    background-color: rgba(255, 255, 255, 0.25);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FEFEFE;
    text-transform: uppercase;
    text-shadow: 0 0 2px black;
    font-size: 16px;
    border-radius: 12px;
}
.mobile-socket-bar{
    position: absolute;
    top: 2px;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.25);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    width: 98%;
    height: 110px;
    margin: 3px;
    border-radius: 12px;
    color: black;
    padding: 5px 10px;
}
.mobile-socket-bar h5{
    font-size: 12px;
    margin-bottom: 5px;
    text-transform: uppercase;
    text-align: center;
    color: black;
}
.mobile-socket-bar-image{
    display: flex;
    justify-content: center;
    
}
.snackbar{
    color: rgb(244, 251, 86);
    transform: rotate( 
45deg);
    background: #FFFFFF40;
    width: 40px;
    height: 40px;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 20px;
    filter: drop-shadow(0 0 2px black);
    border: thin solid rgb(244, 251, 86);
}
.snackbar svg{
    filter: drop-shadow(0 0 2px black);
}
.page-title{
    width: 240px;
    position: absolute;
    font-size: 16px;
    z-index: 3;
    top: 20px;
    right: 60px;
    color: rgb(244, 251, 86);
    text-align: right;
    text-transform: uppercase;
    text-shadow: 0 0 2px black;
}
.mobile-socket-bar-image img{
    width: 70px;
    height: 70px;
    border: thin solid white;
    margin: 0 2px;
    border-radius: 4px;
    font-size: 8px;
    background: repeating-linear-gradient(
        45deg,
        #494d4699,
        #494d4699 20px,
        #494d466c 20px,
        #494d466c 40px
      );
}
.slider li {
    list-style-type: none;
}
.slide .slide-div li img{
    width:fit-content;
}
.carousel .slide img {
    width: fit-content !important;
    vertical-align: top;
    border: 0;
    height: 390px !important;
}
.carousel .control-dots .dot {
    transition: opacity .25s ease-in;
    opacity: .3;
    filter: alpha(opacity=30);
    box-shadow: 0px 0px 2px rgb(0 0 0 / 40%) !important;
    border-radius: 50%;
    width: 20px !important;
    height: 20px !important;
    cursor: pointer;
    display: inline-block;
    margin: 0 6px !important;
    border: 3px solid #f4fb56 !important;
    background: #f4fb5670 !important;
}